import api from '../../utils/api';
import {
    GET_PAYMENT_GATEWAY,
    UPDATE_PAYMENT_GATEWAY,
    CREATE_PAYMENT_GATEWAY,
    DELETE_PAYMENT_GATEWAY,
} from './types';
import { NotificationManager } from 'react-notifications'

export const getPaymentGateways = () => (dispatch) => {
    return new Promise((resolve, reject) => {
        return api.get(`/payment-gateways`).then(res => {
            dispatch({
                type: GET_PAYMENT_GATEWAY,
                payload: res.data.data
            });
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const createPaymentGateway = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/payment-gateways`, data).then(res => {
            dispatch({
                type: CREATE_PAYMENT_GATEWAY,
                payload: res.data.data
            });
            NotificationManager.success(res.data.msg);
            resolve(res.data.data);
        }).catch(err => {
            //console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const updatePaymentGateway = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post(`/payment-gateways/${id}`, data).then(res => {
            dispatch({
                type: UPDATE_PAYMENT_GATEWAY,
                payload: res.data.data
            });
            NotificationManager.success(res.data.msg)
            resolve(res.data)
        }).catch(err => {
            //console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const deletePaymentGateway = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete(`/payment-gateways/${id}`).then(res => {
            dispatch({
                type: DELETE_PAYMENT_GATEWAY,
                payload: res.data.data
            });
            NotificationManager.success(res.data.msg);
            resolve(res.data.data);
        }).catch(err => {
            //console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}