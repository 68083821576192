import api from '../../utils/api';
import {
    GET_OPTION,
    UPDATE_OPTION,
    DELETE_OPTION,
} from './types';
import { NotificationManager } from 'react-notifications'

export const getOption = (name) => (dispatch) => {
    return new Promise((resolve, reject) => {
        return api.get(`/options/${name}`).then(res => {
            dispatch({
                type: GET_OPTION,
                payload: res.data.data
            });
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const updateOption = data => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put('/options', data).then(res => {
            dispatch({
                type: UPDATE_OPTION,
                payload: res.data.data
            });
            NotificationManager.success(res.data.msg);
            resolve(res.data.data);
        }).catch(err => {
            //console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const deleteOption = (name) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete(`/options/${name}`).then(res => {
            dispatch({
                type: DELETE_OPTION,
                payload: res.data.data
            });
            NotificationManager.success(res.data.msg);
            resolve(res.data.data);
        }).catch(err => {
            //console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}