import React, { Component } from 'react';
import { connect } from "react-redux";
import { Form } from "@ant-design/compatible";
import { Button, Input, Drawer, Divider, Row, Col, Radio, Switch } from 'antd';
import { PlusOutlined, DeleteOutlined, CloseOutlined, DragOutlined } from '@ant-design/icons';
import { isMobile } from 'react-device-detect';
import IntlMessage from "../../components/IntlMessage";
import { ChromePicker } from 'react-color';
import { updateAttributes, createAttributes } from "../../redux/actions/AttributesAction";
import { getListAttributeGroups } from '../../redux/actions/AttributeGroupAction';
import BaseSelect from '../../components/Elements/BaseSelect';
import { displayType } from '../../utils/helpers';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

class AttributesForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            attributes: [{ name: '', status: 1, display_type: 'squircle', group_ids: [], values: [{ label: '', value: '' }] }],
            displayColorPicker: {},
        };
    }

    componentDidMount() {
        this.props.getListAttributeGroups();
    }

    componentDidUpdate(prevProps) {
        if (this.props.currentData !== prevProps.currentData) {
            if (this.props.currentData) {
                this.setState({ attributes: [this.props.currentData] });
            }
        }
    }

    handleSave(e) {
        e.preventDefault();

        console.log("Save clicked: ", this.state.attributes);

        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.setState({ isLoading: true });
                try {
                    values.attributes = this.state.attributes;
                    if (this.props.currentData) {
                        await this.props.updateAttributes(this.props.currentData.id, values);
                        this.props.reloadData();
                        this.onClose();
                    } else {
                        await this.props.createAttributes(values);
                        this.props.reloadData();
                        this.props.form.resetFields();
                        this.onClose();
                    }
                    console.log(values)

                } catch (error) {
                    this.setState({ isLoading: false });
                }
            }
        });
    }

    handleAddAttribute = () => {
        this.setState({
            attributes: [...this.state.attributes, { name: '', status: 1, display_type: 'squircle', group_ids: [], values: [{ label: '', value: '' }] }]
        });
    }

    // handleAttributeChange = (index, fieldName, event) => {
    //     const attributes = [...this.state.attributes];
    //     attributes[index][fieldName] = event.target.value;
    //     console.log("attributes: ", attributes);
    //     this.setState({ attributes });
    // }

    handleAttributeChange = (index, fieldName, value) => {
        const attributes = [...this.state.attributes];
        attributes[index][fieldName] = value;  // Use value directly instead of event.target.value
        console.log("attributes: ", attributes);
        this.setState({ attributes });
    }


    handleAttributeValueChange = (attrIndex, valIndex, key, event) => {
        const attributes = [...this.state.attributes];
        attributes[attrIndex].values[valIndex][key] = event.target.value;
        this.setState({ attributes });
    }

    handleColorChange = (attrIndex, valIndex, value) => {
        const attributes = [...this.state.attributes];
        attributes[attrIndex].values[valIndex].value = value.hex;
        this.setState({ attributes });
    }

    handleAddValue = (attrIndex) => {
        const attributes = [...this.state.attributes];
        attributes[attrIndex].values.push({ value: '', label: '' });
        this.setState({ attributes });
    }

    handleDeleteAttribute = (index) => {
        const attributes = [...this.state.attributes];
        attributes.splice(index, 1);
        this.setState({ attributes });
    }

    handleDeleteValue = (attrIndex, valIndex) => {
        const attributes = [...this.state.attributes];
        attributes[attrIndex].values.splice(valIndex, 1);
        this.setState({ attributes });
    }

    onClose = () => {
        this.props.reloadData();
        this.setState({ isLoading: false, attributes: [{ name: '', status: 1, display_type: 'squircle', group_ids: [], values: [{ label: '', value: '' }] }] });
        this.props.onCancel();
    }

    handleColorPickerClick = (attrIndex, valIndex) => {
        const displayColorPicker = { ...this.state.displayColorPicker };
        displayColorPicker[`${attrIndex}-${valIndex}`] = !displayColorPicker[`${attrIndex}-${valIndex}`];
        this.setState({ displayColorPicker });
    };

    handleColorPickerClose = (attrIndex, valIndex) => {
        const displayColorPicker = { ...this.state.displayColorPicker };
        displayColorPicker[`${attrIndex}-${valIndex}`] = false;
        this.setState({ displayColorPicker });
    };

    // onDragEnd = (result, attrIndex) => {
    //     if (!result.destination) return;

    //     const attributes = [...this.state.attributes];
    //     const [reorderedItem] = attributes[attrIndex].values.splice(result.source.index, 1);
    //     attributes[attrIndex].values.splice(result.destination.index, 0, reorderedItem);

    //     this.setState({ attributes });
    //     console.log("attributes: ", attributes);
    // }

    onDragEnd = (result, attrIndex) => {
        if (!result.destination) return;

        const attributes = [...this.state.attributes];
        const [reorderedItem] = attributes[attrIndex].values.splice(result.source.index, 1);
        attributes[attrIndex].values.splice(result.destination.index, 0, reorderedItem);

        // Cập nhật trường sort_order cho mỗi mục
        attributes[attrIndex].values = attributes[attrIndex].values.map((item, index) => ({
            ...item,
            sort_order: index + 1  // hoặc sử dụng index nếu bạn muốn bắt đầu từ 0
        }));

        this.setState({ attributes });
        console.log("attributes: ", attributes);
    }


    render() {
        const { attributes, isLoading, displayColorPicker } = this.state;
        const { visible, currentData, attribute_group_list } = this.props;

        const popover = {
            position: 'absolute',
            zIndex: '2',
            top: '40px',
        };
        const cover = {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
        };

        return (
            <div>
                <Drawer
                    visible={visible}
                    title={currentData ? <IntlMessage id="title.updateRecord" /> : <IntlMessage id="title.createRecord" />}
                    width={isMobile ? '100%' : '45%'}
                    onClose={() => this.onClose()}
                    maskClosable={false}
                    footer={
                        <div style={{ textAlign: 'right' }}>
                            <Button key="back" onClick={() => this.onClose()}>
                                Cancel
                            </Button>
                            <Divider type='vertical' />
                            <Button
                                loading={isLoading}
                                key="submit"
                                type="primary"
                                onClick={(e) => this.handleSave(e)}
                            >
                                {currentData ? `Update` : 'Add new'}
                            </Button>
                        </div>
                    }
                >
                    {visible && (
                        <>
                            <Form layout='vertical'>
                                {attributes?.map((attribute, attrIndex) => (
                                    <div key={attrIndex} style={{
                                        marginBottom: 20,
                                        padding: 10,
                                        background: "#f9f9f9",
                                        borderRadius: 5,
                                        border: '1px dashed #e5e0e0'
                                    }}>
                                        <Row gutter={16} className='mb-3' style={{ position: 'relative' }}>
                                            <Col xs={24} md={12}>
                                                <Form.Item label="Attribute name">
                                                    <Input
                                                        placeholder="Attribute Name"
                                                        value={attribute.name}
                                                        onChange={(e) => this.handleAttributeChange(attrIndex, 'name', e.target.value)}
                                                        className='w-100'
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={12}>
                                                <Form.Item label="Attribute label">
                                                    <Input
                                                        placeholder="Attribute label"
                                                        value={attribute.label}
                                                        onChange={(e) => this.handleAttributeChange(attrIndex, 'label', e.target.value)}
                                                        className='w-100'
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={12}>
                                                <Form.Item label="Status">
                                                    <Switch
                                                        checked={attribute.status === 1}
                                                        defaultValue={1}
                                                        checkedChildren="Active"
                                                        unCheckedChildren="Inactive"
                                                        onChange={(checked) => this.handleAttributeChange(attrIndex, 'status', checked ? 1 : 0)}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={12}>
                                                <Form.Item label="Display type">
                                                    <Radio.Group
                                                        onChange={(e) => this.handleAttributeChange(attrIndex, 'display_type', e.target.value)}
                                                        value={attribute.display_type}
                                                        defaultValue='squircle'
                                                    >
                                                        {
                                                            displayType.map((item, index) => (
                                                                <Radio value={item.value} key={index}>
                                                                    {item.label}
                                                                </Radio>
                                                            ))
                                                        }
                                                    </Radio.Group>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={24}>
                                                <Form.Item label="Group">
                                                    <BaseSelect
                                                        optionValue="id"
                                                        optionLabel="name"
                                                        options={attribute_group_list ?? []}
                                                        placeholder="Choose one or multiple groups"
                                                        value={attribute.group_ids || []}
                                                        mode="multiple"
                                                        onChange={(value) => this.handleAttributeChange(attrIndex, 'group_ids', value)}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Button
                                                type="dashed"
                                                icon={<CloseOutlined />}
                                                onClick={() => this.handleDeleteAttribute(attrIndex)}
                                                size='small'
                                                style={{ position: 'absolute', top: 0, right: 8 }}
                                            />
                                        </Row>
                                        <Divider orientation="left" orientationMargin="0">
                                            Values
                                        </Divider>
                                        <DragDropContext onDragEnd={(result) => this.onDragEnd(result, attrIndex)}>
                                            <Droppable droppableId={`droppable-${attrIndex}`}>
                                                {(provided) => (
                                                    <div {...provided.droppableProps} ref={provided.innerRef}>
                                                        <Row gutter={16}>
                                                            <Col xs={24} md={24}>
                                                                <Row gutter={16}>
                                                                    {attribute.values.map((value, valIndex) => (
                                                                        <Draggable key={valIndex} draggableId={`draggable-${attrIndex}-${valIndex}`} index={valIndex}>
                                                                            {(provided) => (
                                                                                <Col xs={24} md={24} key={valIndex} {...provided.draggableProps} ref={provided.innerRef}>
                                                                                    <div className='wrap-attribute'>
                                                                                        <div className='attribute-drag-icon' {...provided.dragHandleProps}>
                                                                                            <DragOutlined />
                                                                                        </div>
                                                                                        <Input type='hidden' value={value.id} />
                                                                                        <Divider type='vertical' />
                                                                                        <div className='wrap-attribute-color-picker'>
                                                                                            <div
                                                                                                onClick={() => this.handleColorPickerClick(attrIndex, valIndex)}
                                                                                                className='attribute-color-picker'
                                                                                                style={{ background: value.value || 'linear-gradient(90deg, rgb(16,142,233) 0%, rgb(135,208,104) 100%)' }}
                                                                                            />
                                                                                            {displayColorPicker[`${attrIndex}-${valIndex}`] ? (
                                                                                                <div style={popover}>
                                                                                                    <div style={cover} onClick={() => this.handleColorPickerClose(attrIndex, valIndex)} />
                                                                                                    <ChromePicker
                                                                                                        color={value.value}
                                                                                                        onChange={(value) => this.handleColorChange(attrIndex, valIndex, value)}
                                                                                                    />
                                                                                                </div>
                                                                                            ) : null}
                                                                                            <Input
                                                                                                placeholder="Value"
                                                                                                value={value.value}
                                                                                                onChange={(e) => this.handleAttributeValueChange(attrIndex, valIndex, 'value', e)}
                                                                                                className='w-100'
                                                                                                style={{ borderRadius: '0px 5px 5px 0px' }}
                                                                                            />
                                                                                        </div>
                                                                                        <Divider type='vertical' />
                                                                                        <Input
                                                                                            placeholder="Label"
                                                                                            value={value.label}
                                                                                            onChange={(e) => this.handleAttributeValueChange(attrIndex, valIndex, 'label', e)}
                                                                                            className='w-100'
                                                                                        />
                                                                                        <Divider type='vertical' />
                                                                                        <DeleteOutlined className='item-action-btn-remove' onClick={() => this.handleDeleteValue(attrIndex, valIndex)} />
                                                                                    </div>
                                                                                </Col>
                                                                            )}
                                                                        </Draggable>
                                                                    ))}
                                                                    <div><Button type="link" onClick={() => this.handleAddValue(attrIndex)}>Add value</Button></div>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                )}
                                            </Droppable>
                                        </DragDropContext>
                                    </div>
                                ))}
                                <Button type="dashed" onClick={this.handleAddAttribute} style={{ width: '100%' }}>
                                    <PlusOutlined /> Add attribute
                                </Button>
                            </Form>
                        </>
                    )}
                </Drawer>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        attribute_group_list: state.AttributeGroupReducer.attribute_group_list
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getListAttributeGroups: () => dispatch(getListAttributeGroups()),
        updateAttributes: (id, data) => dispatch(updateAttributes(id, data)),
        createAttributes: (data) => dispatch(createAttributes(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: "AttributesForm" })(AttributesForm));

