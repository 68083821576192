import React, { Component } from 'react';
import PageTitle from '../../components/PageTitle';
import TableActionBar from '../../components/TableActionBar/index';
import { DeleteOutlined } from '@ant-design/icons';
import { Table, Modal, Button, Divider, Input, Col, Row, Card, Tooltip, Switch, Alert } from 'antd';
import { connect } from 'react-redux';
import { Form } from "@ant-design/compatible";
import _ from 'lodash';
import { checkPermission } from '../../utils/permission';
import BaseRadios from '../../components/Elements/BaseRadios';

import { updateOption } from '../../redux/actions/OptionAction';
import { getPaymentGateways, createPaymentGateway, updatePaymentGateway, deletePaymentGateway } from '../../redux/actions/PaymentGatewayAction';
import BaseSelect from '../../components/Elements/BaseSelect';

const routes = [
    {
        path: '/',
        breadcrumbName: 'Home',
    },
    {
        path: '/payment-gateways',
        breadcrumbName: 'Payment gateways',
    }
];

const { confirm } = Modal;

class Index extends Component {

    constructor() {
        super();
        this.state = {
            isLoading: false
        }
    }

    async componentDidMount() {
        this.loadData();
    }

    loadData() {
        this.setState({
            isLoading: true
        });
        this.props.getPaymentGateways().then(() => {
            this.setState({
                isLoading: false
            })
        })
    }

    async onSaveConfig(e) {
        e.preventDefault();
        this.props.form.validateFields([
            'paypal_enabled',
            'paypal_enabled_express_on_product_page',
            'paypal_enabled_express_on_cart_page',
            'invoice_prefix',
            'payment_intent'
        ], async (err, values) => {
            if (!err) {
                await this.props.updateOption(values);
            }
        });
    }

    async onAddShield(e) {
        e.preventDefault();
        this.props.form.validateFields(['shield_url'], async (err, values) => {
            values.shield_url = values.shield_url.replace(/\/+$/, '');
            if (!err) {
                await this.props.createPaymentGateway(values);
                this.loadData();
                this.props.form.resetFields();
            }
        });
    }

    onDelete(id) {
        confirm({
            title: 'Warning',
            content: 'Are you sure you want to delete this item? This action cannot be undone.',
            okText: 'OK',
            cancelText: 'Cancel',
            onOk: () => this.props.deletePaymentGateway([id]).then(() => {
                this.loadData();
                setTimeout(() => {
                    Modal.destroyAll();
                }, 800);
            }),
            onCancel() { },
        })
    }

    render() {
        var { isLoading } = this.state;
        const { shield_list, config } = this.props;
        const { getFieldDecorator } = this.props.form;

        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                width: '50px'
            },
            {
                title: 'Url',
                dataIndex: 'shield_url',
                key: 'shield_url'
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                width: '100px',
                render: (text, record) => (
                    <Switch
                        defaultChecked={record.status}
                        checkedChildren="Active"
                        unCheckedChildren="Inactive"
                        onChange={async (value) => {
                            try {
                                await this.props.updatePaymentGateway(record.id, { status: value });
                            } catch (error) {
                                console.log(error);
                            }
                            this.loadData();
                        }}
                    />
                )
            },
            {
                title: 'Actions',
                align: 'center',
                width: '75px',
                render: (text, record) => {
                    return (
                        <>
                            {
                                checkPermission('payment_gateways', 'remove') ? (
                                    <Tooltip title="Delete">
                                        <DeleteOutlined className='item-action-btn-remove item-action-btn icon-action' onClick={() => this.onDelete(record.id)} />
                                    </Tooltip>
                                ) : null
                            }
                        </>
                    )
                }
            },
        ];

        return (
            <div>
                <PageTitle routes={routes} title="Shield" />
                <div>
                    <TableActionBar
                        isShowAddButton={false}
                        isShowDeleteButton={false}
                        showSearch={false}
                        showFilter={false}
                        filters={[]}
                    >
                    </TableActionBar>
                </div>
                <Card>
                    <Row gutter={16}>
                        <Col xs={24} md={12}>
                            <h6>Config shield</h6>
                            <Divider />
                            <Form layout='horizontal' onSubmit={(e) => this.onSaveConfig(e)}>
                                <Form.Item
                                    label='Enable/Disable'
                                    labelCol={{ xs: { span: 24 }, sm: { span: 14 }, style: { textAlign: 'left' } }}
                                    wrapperCol={{ xs: { span: 24 }, sm: { span: 10 } }}
                                >
                                    {getFieldDecorator("paypal_enabled", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Field is required!",
                                            },
                                        ],
                                        initialValue: config ? config.paypal_enabled : null
                                    })(
                                        <BaseRadios
                                            options={[
                                                { label: 'Enable', value: 1 },
                                                { label: 'Disable', value: 0 }
                                            ]}
                                        />
                                    )}
                                </Form.Item>
                                <Form.Item
                                    label='Express Checkout on product page'
                                    labelCol={{ xs: { span: 24 }, sm: { span: 14 }, style: { textAlign: 'left' } }}
                                    wrapperCol={{ xs: { span: 24 }, sm: { span: 10 } }}
                                >
                                    {getFieldDecorator("paypal_enabled_express_on_product_page", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Field is required!",
                                            },
                                        ],
                                        initialValue: config ? config.paypal_enabled_express_on_product_page : null
                                    })(
                                        <BaseRadios
                                            options={[
                                                { label: 'Enable', value: 1 },
                                                { label: 'Disable', value: 0 }
                                            ]}
                                        />
                                    )}
                                </Form.Item>
                                <Form.Item
                                    label='Express Checkout on cart page'
                                    labelCol={{ xs: { span: 24 }, sm: { span: 14 }, style: { textAlign: 'left' } }}
                                    wrapperCol={{ xs: { span: 24 }, sm: { span: 10 } }}
                                >
                                    {getFieldDecorator("paypal_enabled_express_on_cart_page", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Field is required!",
                                            },
                                        ],
                                        initialValue: config ? config.paypal_enabled_express_on_cart_page : null
                                    })(
                                        <BaseRadios
                                            options={[
                                                { label: 'Enable', value: 1 },
                                                { label: 'Disable', value: 0 }
                                            ]}
                                        />
                                    )}
                                </Form.Item>
                                <Form.Item
                                    label='Invoice Prefix'
                                    labelCol={{ xs: { span: 24 }, sm: { span: 14 }, style: { textAlign: 'left' } }}
                                    wrapperCol={{ xs: { span: 24 }, sm: { span: 10 } }}
                                >
                                    {getFieldDecorator("invoice_prefix", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Field is required!",
                                            },
                                        ],
                                        initialValue: config ? config.invoice_prefix : 'WC-'
                                    })(
                                        <Input />
                                    )}
                                </Form.Item>
                                <Form.Item
                                    label='Payment Intent'
                                    labelCol={{ xs: { span: 24 }, sm: { span: 14 }, style: { textAlign: 'left' } }}
                                    wrapperCol={{ xs: { span: 24 }, sm: { span: 10 } }}
                                >
                                    {getFieldDecorator("payment_intent", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Field is required!",
                                            },
                                        ],
                                        initialValue: config ? config.payment_intent : 'capture'
                                    })(
                                        <BaseSelect
                                            options={[
                                                { label: 'Capture', value: 'capture' },
                                                //{ label: 'Authorize', value: 'authorize' }
                                            ]}
                                        />

                                    )}
                                </Form.Item>
                                <Form.Item style={{ textAlign: 'right' }}>
                                    <Button
                                        htmlType="submit"
                                        key="submit"
                                        type="primary"
                                    >
                                        Save
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Col>
                        <Col xs={24} md={12}>
                            <h6>Shield list</h6>
                            <Divider />
                            <Form layout='vertical' onSubmit={(e) => this.onAddShield(e)}>
                                <Form.Item label='Shield url'>
                                    {getFieldDecorator("shield_url", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Field is required!",
                                            },
                                        ],
                                        initialValue: null
                                    })(
                                        <Input placeholder='https://amozadesigns.com' />
                                    )}
                                </Form.Item>
                                {checkPermission('payment_gateways', 'create') ? (
                                    <Form.Item style={{ textAlign: 'right' }}>
                                        <Button
                                            htmlType="submit"
                                            key="submit"
                                            type="primary"
                                        >
                                            Add
                                        </Button>
                                    </Form.Item>
                                ) : null}
                            </Form>
                            <div className='mt-3 mb-3'>
                                <Alert message="You can only activate one Shield at a time." type="warning" />
                            </div>
                            <Table
                                rowKey="id"
                                size='small'
                                tableLayout='auto'
                                columns={columns}
                                loading={isLoading}
                                dataSource={shield_list ?? []}
                                pagination={false}
                                scroll={{
                                    x: 'max-content'
                                }}
                            />
                        </Col>
                    </Row>
                </Card>
            </div >
        )
    }
}

const mapStateToProps = state => {
    return {
        shield_list: state.PaymentGatewayReducer.shield_list,
        config: state.PaymentGatewayReducer.config
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateOption: (data) => dispatch(updateOption(data)),
        getPaymentGateways: (params) => dispatch(getPaymentGateways(params)),
        createPaymentGateway: (data) => dispatch(createPaymentGateway(data)),
        updatePaymentGateway: (id, data) => dispatch(updatePaymentGateway(id, data)),
        deletePaymentGateway: (id) => dispatch(deletePaymentGateway(id))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: "Index" })(Index));
