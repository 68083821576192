import React, { Component } from 'react';
import PageTitle from '../../components/PageTitle';
import TableActionBar from '../../components/TableActionBar/index';
import { PlusOutlined } from '@ant-design/icons';
import { Table, Modal, Button, Divider, Input, Col, Row, Card } from 'antd';
import { connect } from 'react-redux';
import { Form } from "@ant-design/compatible";
import qs from 'qs';
import _ from 'lodash';
import { cleanObject, isValidDate } from '../../utils/helpers';
import { checkPermission } from '../../utils/permission';

import { getAllTags, createTags, deleteTags } from '../../redux/actions/TagsAction';

const routes = [
    {
        path: '/',
        breadcrumbName: 'Home',
    },
    {
        path: '/tags',
        breadcrumbName: 'Tags list',
    }
]

class TagsList extends Component {

    constructor() {
        super();
        this.state = {
            isLoading: false,
            selectedRowKeys: []
        }
    }

    componentDidMount() {
        this.loadData();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.search !== this.props.location.search) {
            var query = qs.parse(nextProps.location.search.slice(1));
            this.setState({ isLoading: true });
            this.props.getAllTags(query).then(() => {
                this.setState({ isLoading: false });
            })
        }
    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys })
    }

    onChangeTable(pagination, sorter) {
        var query = qs.parse(this.props.location.search.slice(1));
        if (!_.isEmpty(pagination)) {
            query = {
                ...query,
                page: pagination.current,
                per_page: pagination.pageSize,
            }
        }
        if (!_.isEmpty(sorter)) {
            if (sorter.order) {
                query = {
                    ...query,
                    sort_field: sorter.field,
                    sort_type: sorter.order === 'ascend' ? 'ASC' : 'DESC'
                }
            } else {
                delete query.sort_field;
                delete query.sort_type;
            }
        }

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query),
        })
    }

    loadData() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({
            isLoading: true
        });
        this.props.getAllTags(query).then(() => {
            this.setState({
                isLoading: false
            })
        })
    }

    onChangeFilter(name, value) {
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            [name]: value
        }
        query = cleanObject(query);
        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query)
        })
    }

    async onSubmit(e) {
        e.preventDefault();
        this.props.form.validateFields(['name'], async (err, values) => {
            if (!err) {
                await this.props.createTags(values).then(res => {
                    this.props.form.resetFields();
                    this.loadData();
                })
            }
        });
    }

    onDelete() {
        this.props.deleteTags(this.state.selectedRowKeys).then(() => {
            this.setState({ selectedRowKeys: [] })
            setTimeout(() => {
                Modal.destroyAll();
            }, 800)
            this.loadData()
        })
    }

    render() {
        var { selectedRowKeys, isLoading } = this.state;
        const { list_tags, pagination } = this.props;
        var query = qs.parse(this.props.location.search.slice(1));
        var hasSelected = selectedRowKeys.length > 0;
        const { getFieldDecorator } = this.props.form;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };

        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                sorter: true,
                key: 'id',
                width: '50px'
            },
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                sorter: true
            },
            {
                title: 'Creator',
                dataIndex: 'created_user',
                key: 'created_user',
                sorter: true,
                width: '200px',
                render: (text, record) => {
                    return (<>{text}</>)
                }
            },
            {
                title: 'Created at',
                dataIndex: 'created_at',
                key: 'created_at',
                sorter: true,
                width: '150px',
                render: (text, record) => {
                    return (
                        <div>{isValidDate(text)}</div>
                    )
                }
            }
        ];

        return (
            <div>
                <PageTitle routes={routes} title="Tags list" />
                <div>
                    <TableActionBar
                        isShowAddButton={false}
                        isShowDeleteButton={checkPermission('tags', 'remove')}
                        disabled={!selectedRowKeys.length ? true : false}
                        onDelete={() => this.onDelete()}
                        showSearch={true}
                        showFilter={false}
                        onSearch={(value) => this.onChangeFilter('keyword', value)}
                        defaultKeyword={query.keyword}
                        filters={[]}
                    >
                        {
                            hasSelected ? <span>Selecting {selectedRowKeys.length} records </span> : null
                        }
                    </TableActionBar>
                </div>
                <Card>
                    <Row gutter={16}>
                        <Col xs={24} md={6}>
                            <h6>Add tags</h6>
                            <Divider />
                            <Form layout='vertical' onSubmit={(e) => this.onSubmit(e)}>
                                <Form.Item label="Name">
                                    {getFieldDecorator("name", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Field is required!",
                                            },
                                        ],
                                    })(
                                        <Input />
                                    )}
                                </Form.Item>
                                {
                                    checkPermission('tags', 'create') ? (
                                        <Form.Item>
                                            <Button
                                                htmlType="submit"
                                                key="submit"
                                                type="primary"
                                                icon={<PlusOutlined />}
                                            >
                                                Add
                                            </Button>
                                        </Form.Item>
                                    ) : null
                                }
                            </Form>
                        </Col>
                        <Col xs={24} md={18}>
                            <Table
                                rowKey="id"
                                size='small'
                                tableLayout='auto'
                                columns={columns}
                                loading={isLoading}
                                dataSource={list_tags ?? []}
                                rowSelection={rowSelection}
                                pagination={{
                                    pageSize: pagination.perPage,
                                    current: pagination.currentPage,
                                    total: pagination.total,
                                    showTotal: total => `Total ${total} records`
                                }}
                                onChange={(pagination, filters, sorter) => this.onChangeTable(pagination, sorter)}
                                scroll={{
                                    x: 'max-content'
                                }}
                            />
                        </Col>
                    </Row>
                </Card>
            </div >
        )
    }
}

const mapStateToProps = state => {
    return {
        pagination: state.TagsReducer.pagination,
        list_tags: state.TagsReducer.list_tags,
        authUser: state.auth.authUser
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getAllTags: filter => dispatch(getAllTags(filter)),
        createTags: (data) => dispatch(createTags(data)),
        deleteTags: (store_id, ids) => dispatch(deleteTags(store_id, ids))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: "TagsList" })(TagsList));
