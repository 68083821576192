import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import Home from './home';
import UserPermission from './user/UserPermission';
import RoleList from './role/RoleList';
import UserDetail from './user/UserDetail';
import UserList from './user/UserList';
import Profile from './auth/Profile';
import Config from './config';
import ProductsList from './products/ProductsList';
import TagsList from './tags/TagsList';
import PostsList from './posts/PostsList';
import ProductCategoriesList from './product-categories/ProductCategoriesList';
import ManageMenus from './manage-menus/ManageMenus';
import PostCategoriesList from './post-categories/PostCategoriesList';
import AttributesList from './attributes/AttributesList';
import AttributeGroupList from './attribute-group/AttributeGroupList';
import BannerList from './banner/BannerList';
import PaymentGateway from './payment-gateways';

import { Typography } from 'antd';
const { Title } = Typography;

const ProtectedRoute = ({ authUser, permission, component: Component, ...rest }) => (
    <Route
        {...rest}
        // render={(props) => (
        //     authUser ? <Component {...props} /> : <Redirect to='/login' />
        // )} 
        render={(props) => {
            if (authUser) {
                let availablePermissions = Object.keys(authUser.permissions);
                let isAccess = false;
                if (authUser.role_code == 'ADMIN') isAccess = true;
                else {
                    if (!permission || availablePermissions.indexOf(permission) >= 0) isAccess = true;
                }

                if (isAccess) return <Component {...props} />;
                // else return <Redirect to='/error/403' />;
                else return (
                    <div className="text-center mt-4">
                        <Title>Permission access is denied</Title>
                    </div>
                )
            } else {
                return <Redirect to='/login' />;
            }
        }}
    />
)

const routes = [
    {
        path: '/',
        component: Home,
        permission: null
    },
    {
        path: '/profile',
        component: Profile,
        permission: null
    },
    {
        path: '/users',
        component: UserList,
        permission: 'users'
    },
    {
        path: '/roles',
        component: RoleList,
        permission: 'roles'
    },
    {
        path: '/users/:id',
        component: UserDetail,
        permission: 'users'
    },
    {
        path: '/permissions',
        component: UserPermission,
        permission: 'permissions'
    },
    // config
    {
        path: '/config',
        component: Config,
        permission: 'config'
    },
    {
        path: '/products',
        component: ProductsList,
        permission: 'products'
    },
    {
        path: '/product-categories',
        component: ProductCategoriesList,
        permission: 'product_categories'
    },
    {
        path: '/tags',
        component: TagsList,
        permission: 'tags'
    },
    {
        path: '/posts',
        component: PostsList,
        permission: 'posts'
    },
    {
        path: '/manage-menus',
        component: ManageMenus,
        permission: 'manage_menus'
    },
    {
        path: '/post-categories',
        component: PostCategoriesList,
        permission: 'post_categories'
    },
    {
        path: '/attributes',
        component: AttributesList,
        permission: 'attributes'
    },
    {
        path: '/banner',
        component: BannerList,
        permission: 'banner'
    },
    {
        path: '/attribute-groups',
        component: AttributeGroupList,
        permission: 'attribute_groups'
    },
    {
        path: '/payment-gateways',
        component: PaymentGateway,
        permission: 'payment_gateways'
    }
];

//console.log(routes);  

class Routes extends Component {

    render() {
        var { authUser } = this.props;

        return (
            <Switch>
                {
                    routes.map((route, index) => {
                        return (
                            <ProtectedRoute exact authUser={authUser} permission={route.permission} path={route.path} component={route.component} key={index} />
                        )
                    })
                }
            </Switch>
        )
    }
}

function mapStateToProps(state) {
    //console.log(state);
    return {
        authUser: state.auth.authUser
    }
}

export default connect(mapStateToProps, null)(Routes);

