import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, Row, Col, Typography, Divider, Drawer, Space } from 'antd';
import { DeleteOutlined, DragOutlined } from '@ant-design/icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ChromePicker } from 'react-color';
import IntlMessage from "../../components/IntlMessage";
import { isMobile } from 'react-device-detect';
import { NotificationManager } from 'react-notifications';
import { v4 as uuidv4 } from 'uuid';

const { Title } = Typography;
const { Option } = Select;

const EditOptions = (props) => {

    const { visible, setVisible, data, setFormFields } = props;

    const [form] = Form.useForm();
    const [fieldType, setFieldType] = useState(data.type);
    const [displayColorPicker, setDisplayColorPicker] = useState({});
    const [values, setValues] = useState([]);
    const key = data.key;

    useEffect(() => {
        if (data.options) {
            setValues(data.options);
        }
    }, [data.options]);

    useEffect(() => {
        setFieldType(data.type);
    }, [data.type]);

    const handleColorPickerClick = (valIndex) => {
        const newDisplayColorPicker = { ...displayColorPicker };
        newDisplayColorPicker[`${valIndex}`] = !displayColorPicker[`${valIndex}`];
        setDisplayColorPicker(newDisplayColorPicker);
    };

    const handleColorPickerClose = (valIndex) => {
        const newDisplayColorPicker = { ...displayColorPicker };
        newDisplayColorPicker[`${valIndex}`] = false;
        setDisplayColorPicker(newDisplayColorPicker);
    };


    const handleColorChange = (valIndex, value) => {
        const newValues = [...values];
        newValues[valIndex].value = value.hex;
        setValues(newValues);
    };

    const handleAddValue = () => {
        const newValues = [...values];
        newValues.push({ id: uuidv4(), value: '', label: '' });
        setValues(newValues);
    };

    const handleDeleteValue = (valIndex) => {
        const newValues = [...values];
        newValues.splice(valIndex, 1);
        setValues(newValues);
    };

    const handleValueChange = (valIndex, key, event) => {
        const newValues = [...values];
        newValues[valIndex][key] = event.target.value;
        setValues(newValues);
    };

    const onDragEnd = (result) => {
        if (!result.destination) return;

        let newValues = [...values];  // Use 'let' since you're reassigning 'newValues'
        const [reorderedItem] = newValues.splice(result.source.index, 1);
        newValues.splice(result.destination.index, 0, reorderedItem);

        newValues = newValues.map((item, index) => ({
            ...item,
            sort_order: index + 1  // Update sort order
        }));

        setValues(newValues);  // Update state with new sorted values
    };

    const onClose = () => {
        setVisible(false);
    }

    const handleSave = (e) => {
        e.preventDefault();

        // Kiểm tra key và các giá trị cần thiết có tồn tại không
        if (key && fieldType) {
            // Cập nhật state formFields với giá trị mới
            setFormFields((prevFormFields) => {
                // Cập nhật options trong array formFields nếu key tồn tại
                const updatedFields = prevFormFields.map((item) => {
                    if (item.key === key) {
                        return {
                            ...item,
                            type: fieldType, // Cập nhật loại trường
                            options: values, // Cập nhật danh sách các tùy chọn
                        };
                    }
                    return item; // Giữ nguyên các đối tượng khác
                });

                console.log('Updated formFields:', updatedFields);
                return updatedFields;
            });

            NotificationManager.success('Record updated successfully');
        } else {
            console.error('Missing key, fieldType, or values');
        }
    };

    //console.log('values:', values);

    const popover = {
        position: 'absolute',
        zIndex: '2',
        top: '40px',
    };
    const cover = {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
    };

    return (
        <Drawer
            visible={visible}
            title={<IntlMessage id="title.updateRecord" />}
            width={isMobile ? '100%' : '45%'}
            onClose={() => onClose()}
            maskClosable={false}
            footer={
                <div style={{ textAlign: 'right', }}>
                    <Space>
                        <Button key="back" onClick={() => onClose()}>
                            Cancel
                        </Button>
                        <Button
                            key="submit"
                            type="primary"
                            onClick={(e) => handleSave(e)}
                        >
                            Update
                        </Button>
                    </Space>
                </div>
            }
        >
            <Form form={form} autoComplete="off" layout='vertical' className='mb-4'>
                <Row gutter={16}>
                    <Col xs={24} md={24}>
                        <Title level={5} className="mt-3 mb-3">Label: {data.label}</Title>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item label="Field Type">
                            <Select
                                value={fieldType || data.type}
                                onChange={setFieldType}
                                placeholder="Select field type"
                                className='w-100'
                                defaultValue={data.type}
                            >
                                <Option value="checkbox">Checkbox</Option>
                                <Option value="radio">Radio buttons</Option>
                                <Option value="dropdownlist">Dropdown list</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={24}>
                        <Form.Item label="Options">
                            <div style={{
                                padding: 10,
                                background: "#f9f9f9",
                                borderRadius: 5,
                                border: '1px dashed #e5e0e0'
                            }}>
                                <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
                                    <Droppable droppableId={`droppable`}>
                                        {(provided) => (
                                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                                <Row gutter={16}>
                                                    <Col xs={24} md={24}>
                                                        <Row gutter={16}>
                                                            {values.map((value, valIndex) => (
                                                                <Draggable key={valIndex} draggableId={`draggable-${valIndex}`} index={valIndex}>
                                                                    {(provided) => (
                                                                        <Col xs={24} md={24} key={valIndex} {...provided.draggableProps} ref={provided.innerRef}>
                                                                            <div className='wrap-attribute'>
                                                                                <div className='attribute-drag-icon' {...provided.dragHandleProps}>
                                                                                    <DragOutlined />
                                                                                </div>
                                                                                <Divider type='vertical' />
                                                                                <div className='wrap-attribute-color-picker'>
                                                                                    <div
                                                                                        onClick={() => handleColorPickerClick(valIndex)}
                                                                                        className='attribute-color-picker'
                                                                                        style={{ background: value.value || 'linear-gradient(90deg, rgb(16,142,233) 0%, rgb(135,208,104) 100%)' }}
                                                                                    />
                                                                                    {displayColorPicker[`${valIndex}`] ? (
                                                                                        <div style={popover}>
                                                                                            <div style={cover} onClick={() => handleColorPickerClose(valIndex)} />
                                                                                            <ChromePicker
                                                                                                color={value.value}
                                                                                                onChange={(value) => handleColorChange(valIndex, value)}
                                                                                            />
                                                                                        </div>
                                                                                    ) : null}
                                                                                    <Input
                                                                                        placeholder="Value"
                                                                                        value={value.value}
                                                                                        onChange={(e) => handleValueChange(valIndex, 'value', e)}
                                                                                        className='w-100'
                                                                                        style={{ borderRadius: '0px 5px 5px 0px' }}
                                                                                    />
                                                                                </div>
                                                                                <Divider type='vertical' />
                                                                                <Input
                                                                                    placeholder="Label"
                                                                                    value={value.label}
                                                                                    onChange={(e) => handleValueChange(valIndex, 'label', e)}
                                                                                    className='w-100'
                                                                                />
                                                                                <Divider type='vertical' />
                                                                                <DeleteOutlined className='item-action-btn-remove' onClick={() => handleDeleteValue(valIndex)} />
                                                                            </div>
                                                                        </Col>
                                                                    )}
                                                                </Draggable>
                                                            ))}
                                                            <Button type="link" onClick={() => handleAddValue()}>Add value</Button>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </div>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Drawer>
    );
};

export default EditOptions;
