import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, Space, Row, Col, Table, Typography, InputNumber, Switch, Divider, Radio, Checkbox, Tooltip } from 'antd';
import { CloseOutlined, DeleteOutlined, DragOutlined, EditOutlined } from '@ant-design/icons';
import { NotificationManager } from 'react-notifications';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ChromePicker } from 'react-color';
import EditOptions from './EditOptions';
import { v4 as uuidv4 } from 'uuid';

const { Title } = Typography;
const { Option } = Select;

// Drag handle component
const DragHandle = SortableHandle(() => (
    <DragOutlined className='item-action-btn-drag' onMouseDown={(e) => e.preventDefault()} />
));
// Function to move an item in an array
const moveItemInArray = (array, fromIndex, toIndex) => {
    const newArray = [...array];
    const [removed] = newArray.splice(fromIndex, 1);
    newArray.splice(toIndex, 0, removed);
    return newArray;
};

// Sortable item component
const SortableItem = SortableElement((props) => <tr {...props} />);

// Sortable body container
const SortableBody = SortableContainer((props) => <tbody {...props} />);

const CustomOptions = ({ sendDataToParent, currentData }) => {
    const [form] = Form.useForm();
    const [formFields, setFormFields] = useState(currentData?.custom_options || []);
    const [fieldType, setFieldType] = useState('');
    const [fieldLabel, setFieldLabel] = useState('');
    const [fieldPlaceholder, setFieldPlaceholder] = useState('');
    const [fieldExample, setFieldExample] = useState('');
    const [entryLimit, setEntryLimit] = useState(1);
    const [dragEnabled, setDragEnabled] = useState(true); // Trạng thái để kiểm soát Drag-and-Drop
    const [displayColorPicker, setDisplayColorPicker] = useState({});
    const [values, setValues] = useState([{ id: uuidv4(), label: '', value: '' }]);
    const [editData, setEditData] = useState({});
    const [editModalVisible, setEditModalVisible] = useState(false);

    useEffect(() => {
        sendDataToParent(formFields);
    }, [formFields, sendDataToParent]);

    const handleAddFields = () => {
        if (!fieldType) {
            NotificationManager.error('Please select a field type.');
            return;
        }

        if (!fieldLabel) {
            NotificationManager.error('Please enter a field label.');
            return;
        }

        const generatedFieldKey = fieldLabel.toLowerCase().replace(/ /g, '_');

        const isDuplicateKey = formFields.some(field => field.key === generatedFieldKey);

        if (isDuplicateKey) {
            NotificationManager.error('Field key already exists. Please enter a unique field key.');
            return;
        }

        const newFields = [{
            type: fieldType,
            label: fieldLabel,
            placeholder: fieldPlaceholder,
            key: generatedFieldKey,
            entry_limit: entryLimit,
            required: 0,
            example: fieldExample,
            options: values,
        }];

        setFormFields([...formFields, ...newFields]);
        setFieldLabel('');
        setFieldPlaceholder('');
        setEntryLimit(1);
        setFieldExample('');
        setValues([{ id: uuidv4(), label: '', value: '' }]);
        sendDataToParent([...formFields, ...newFields]);
    };

    const handleRemoveField = (key) => {
        const updatedFields = formFields.filter(field => field.key !== key);
        setFormFields(updatedFields);
        sendDataToParent(updatedFields);
    };

    const onSortStart = () => {
        console.log('Sorting started...');
        // Add the noselect class to prevent text selection during drag
        document.body.classList.add('noselect');
    };

    const onSortEnd = ({ oldIndex, newIndex }) => {

        // Remove the noselect class after sorting is done
        document.body.classList.remove('noselect');

        if (oldIndex !== newIndex) {
            // Move items in the array based on drag
            const newData = moveItemInArray(formFields.slice(), oldIndex, newIndex);

            // Map over the updated array to assign the new 'order' field
            const updatedData = newData.map((item, index) => ({
                ...item,
                order: index + 1, // Update order based on new index
            }));

            // Update form fields with the reordered data
            setFormFields(updatedData);
            sendDataToParent(updatedData);

            console.log('Sorted items: ', updatedData); // Log the sorted array for debugging
        }
    };

    // Draggable container component
    const DraggableContainer = (props) => (
        <SortableBody
            useDragHandle
            disableAutoscroll
            helperClass="row-dragging"
            onSortStart={onSortStart}
            onSortEnd={onSortEnd}
            {...props}
        />
    );

    // Draggable body row component
    const DraggableBodyRow = ({ className, style, ...restProps }) => {
        const index = formFields.findIndex((x) => x.key === restProps['data-row-key']);
        return <SortableItem index={index} {...restProps} />;
    };

    const handleColumnChange = (key, field, value) => {
        const updatedFields = formFields.map(item =>
            item.key === key ? { ...item, [field]: value } : item
        );
        setFormFields(updatedFields);
        sendDataToParent(updatedFields);
    };

    const handleInputFocus = (event) => {
        setDragEnabled(false); // Vô hiệu hóa Drag-and-Drop khi có ô input đang được chỉnh sửa
        event.target.focus(); // Đảm bảo focus vào ô input
    };

    const handleInputBlur = () => {
        setDragEnabled(true); // Bật lại Drag-and-Drop khi không còn ô input nào đang được chỉnh sửa
    };

    const handleColorPickerClick = (valIndex) => {
        const newDisplayColorPicker = { ...displayColorPicker };
        newDisplayColorPicker[`${valIndex}`] = !displayColorPicker[`${valIndex}`];
        setDisplayColorPicker(newDisplayColorPicker);
    };

    const handleColorPickerClose = (valIndex) => {
        const newDisplayColorPicker = { ...displayColorPicker };
        newDisplayColorPicker[`${valIndex}`] = false;
        setDisplayColorPicker(newDisplayColorPicker);
    };


    const handleColorChange = (valIndex, value) => {
        const newValues = [...values];
        newValues[valIndex].value = value.hex;
        setValues(newValues);
    };

    const handleAddValue = () => {
        const newValues = [...values];
        newValues.push({ id: uuidv4(), value: '', label: '' });
        setValues(newValues);
    };

    const handleDeleteValue = (valIndex) => {
        const newValues = [...values];
        newValues.splice(valIndex, 1);
        setValues(newValues);
    };

    const handleValueChange = (valIndex, key, event) => {
        const newValues = [...values];
        newValues[valIndex][key] = event.target.value;
        setValues(newValues);
    };

    const onDragEnd = (result) => {
        if (!result.destination) return;

        let newValues = [...values];  // Use 'let' since you're reassigning 'newValues'
        const [reorderedItem] = newValues.splice(result.source.index, 1);
        newValues.splice(result.destination.index, 0, reorderedItem);

        newValues = newValues.map((item, index) => ({
            ...item,
            sort_order: index + 1  // Update sort order
        }));

        setValues(newValues);  // Update state with new sorted values
    };

    const onEdit = (key) => {
        const editData = formFields.find(item => item.key === key);
        setEditData(editData);
        setEditModalVisible(true);
    }

    const columns = [
        {
            title: 'Sort',
            dataIndex: 'sort',
            width: 30,
            className: 'drag-visible',
            render: () => <DragHandle />,
        },
        {
            title: 'Field Type',
            dataIndex: 'type',
            key: 'type',
            width: 100,
            className: 'drag-visible',
        },
        {
            title: 'Field Label',
            dataIndex: 'label',
            key: 'label',
            className: 'drag-visible',
            render: (text, record) => (
                <Input
                    placeholder="Enter label"
                    className="w-100"
                    value={record.label}
                    onChange={(e) => handleColumnChange(record.key, 'label', e.target.value)}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                />
            )
        },
        {
            title: 'Field Placeholder',
            dataIndex: 'placeholder',
            key: 'placeholder',
            className: 'drag-visible',
            render: (text, record) => {
                if (["text", "textarea", "dropdownlist"].includes(record.type)) {
                    return (
                        <Input
                            placeholder="Enter placeholder"
                            className="w-100"
                            value={record.placeholder}
                            onChange={(e) => handleColumnChange(record.key, 'placeholder', e.target.value)}
                            onFocus={handleInputFocus}
                            onBlur={handleInputBlur}
                        />
                    )
                }
            }
        },
        {
            title: 'Field Example',
            dataIndex: 'example',
            key: 'example',
            className: 'drag-visible',
            render: (text, record) => {
                if (["text", "textarea"].includes(record.type)) {
                    return (
                        <Input
                            placeholder="Enter example"
                            className="w-100"
                            value={record.example}
                            onChange={(e) => handleColumnChange(record.key, 'example', e.target.value)}
                            onFocus={handleInputFocus}
                            onBlur={handleInputBlur}
                        />
                    )
                }
            }
        },
        {
            title: 'Options',
            dataIndex: 'options',
            key: 'options',
            width: 200,
            className: 'drag-visible',
            render: (text, record) => {
                if (["checkbox", "radio", "dropdownlist"].includes(record.type)) {
                    let renderOptions;

                    switch (record.type) {
                        case 'checkbox':
                            renderOptions = (
                                <Checkbox.Group>
                                    {record.options.map((value, index) => (
                                        <Checkbox key={index} value={value.value}>{value.label}</Checkbox>
                                    ))}
                                </Checkbox.Group>
                            );
                            break;
                        case 'radio':
                            renderOptions = (
                                <Radio.Group>
                                    {record.options.map((value, index) => (
                                        <Radio key={index} value={value.value}>{value.label}</Radio>
                                    ))}
                                </Radio.Group>
                            );
                            break;
                        case 'dropdownlist':
                            renderOptions = (
                                <Select style={{ width: 160 }} >
                                    {record.options.map((value, index) => (
                                        <Option key={index} value={value.value}>{value.label}</Option>
                                    ))}
                                </Select>
                            );
                            break;
                        default:
                            return null;
                    }

                    return (
                        <div className='custom-option-col'>
                            {renderOptions}
                            <Tooltip title="Edit options">
                                <EditOutlined
                                    onClick={() => onEdit(record.key)}
                                    className='item-action-btn primary-color'
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        right: '-10px',
                                        marginTop: '-8px'
                                    }}
                                />
                            </Tooltip>
                        </div>
                    );
                }
            }
        },
        {
            title: 'Entry Limit',
            dataIndex: 'entry_limit',
            key: 'entry_limit',
            className: 'drag-visible',
            width: 100,
            render: (text, record) => {
                if (["text", "textarea", "upload"].includes(record.type)) {
                    return (
                        <InputNumber
                            min={0}
                            className="w-5"
                            value={record.entry_limit}
                            onChange={(value) => handleColumnChange(record.key, 'entry_limit', value)}
                            onFocus={handleInputFocus}
                            onBlur={handleInputBlur}
                        />
                    )
                }
            }
        },
        {
            title: 'Required',
            dataIndex: 'required',
            key: 'required',
            className: 'drag-visible',
            render: (text, record) => (
                <Switch
                    size="small"
                    checked={record.required === 1}
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    onChange={(value) => handleColumnChange(record.key, 'required', value ? 1 : 0)}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                />
            )
        },
        {
            title: 'Action',
            key: 'action',
            width: 50,
            align: 'center',
            className: 'drag-visible',
            render: (text, record) => (
                <Space size="middle">
                    <CloseOutlined
                        className='item-action-btn-remove'
                        onClick={() => handleRemoveField(record.key)}
                    />
                </Space>
            ),
        },
    ];

    const popover = {
        position: 'absolute',
        zIndex: '2',
        top: '40px',
    };
    const cover = {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
    };

    //console.log('formFields', formFields);

    return (
        <>
            <Form form={form} autoComplete="off" layout='vertical' className='mb-4'>
                <Row gutter={16}>
                    <Col xs={24} md={24}>
                        <Title level={5} className="mt-3 mb-3">Select fields for custom field setup</Title>
                    </Col>
                    <Col xs={24} md={4}>
                        <Form.Item label="Field Type">
                            <Select
                                value={fieldType || undefined}
                                onChange={setFieldType}
                                placeholder="Select field type"
                                className='w-100'
                            >
                                <Option value="upload">Upload Image</Option>
                                <Option value="text">Text Input</Option>
                                <Option value="textarea">Text Area</Option>
                                <Option value="checkbox">Checkbox</Option>
                                <Option value="radio">Radio buttons</Option>
                                <Option value="dropdownlist">Dropdown list</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={4}>
                        <Form.Item label="Field Label">
                            <Input
                                value={fieldLabel}
                                onChange={(e) => setFieldLabel(e.target.value)}
                                placeholder="Enter field label"
                                className='w-100'
                            />
                        </Form.Item>
                    </Col>
                    {!["upload", "checkbox", "radio"].includes(fieldType) && fieldType !== '' && (
                        <Col xs={24} md={4}>
                            <Form.Item label="Field Placeholder">
                                <Input
                                    value={fieldPlaceholder}
                                    onChange={(e) => setFieldPlaceholder(e.target.value)}
                                    placeholder="Enter field placeholder"
                                    className='w-100'
                                />
                            </Form.Item>
                        </Col>
                    )}

                    {["text", "textarea"].includes(fieldType) && (
                        <>
                            <Col xs={24} md={4}>
                                <Form.Item label="Field Example">
                                    <Input
                                        value={fieldExample}
                                        onChange={(e) => setFieldExample(e.target.value)}
                                        placeholder="Enter field example"
                                        className='w-100'
                                    />
                                </Form.Item>
                            </Col>
                        </>
                    )}
                    {!["dropdownlist", "checkbox", "radio"].includes(fieldType) && fieldType !== '' && (
                        <Col xs={24} md={4}>
                            <Form.Item label="Entry Limit">
                                <InputNumber
                                    value={entryLimit}
                                    onChange={(value) => setEntryLimit(value)}
                                    className='w-100'
                                    min={1}
                                />
                            </Form.Item>
                        </Col>
                    )}
                    {["dropdownlist", "checkbox", "radio"].includes(fieldType) && (
                        <Col xs={24} md={24}>
                            <Form.Item label="Options">
                                <div style={{
                                    padding: 10,
                                    background: "#f9f9f9",
                                    borderRadius: 5,
                                    border: '1px dashed #e5e0e0'
                                }}>
                                    <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
                                        <Droppable droppableId={`droppable`}>
                                            {(provided) => (
                                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                                    <Row gutter={16}>
                                                        <Col xs={24} md={24}>
                                                            <Row gutter={16}>
                                                                {values.map((value, valIndex) => (
                                                                    <Draggable key={valIndex} draggableId={`draggable-${valIndex}`} index={valIndex}>
                                                                        {(provided) => (
                                                                            <Col xs={24} md={24} key={valIndex} {...provided.draggableProps} ref={provided.innerRef}>
                                                                                <div className='wrap-attribute'>
                                                                                    <div className='attribute-drag-icon' {...provided.dragHandleProps}>
                                                                                        <DragOutlined />
                                                                                    </div>
                                                                                    <Divider type='vertical' />
                                                                                    <div className='wrap-attribute-color-picker'>
                                                                                        <div
                                                                                            onClick={() => handleColorPickerClick(valIndex)}
                                                                                            className='attribute-color-picker'
                                                                                            style={{ background: value.value || 'linear-gradient(90deg, rgb(16,142,233) 0%, rgb(135,208,104) 100%)' }}
                                                                                        />
                                                                                        {displayColorPicker[`${valIndex}`] ? (
                                                                                            <div style={popover}>
                                                                                                <div style={cover} onClick={() => handleColorPickerClose(valIndex)} />
                                                                                                <ChromePicker
                                                                                                    color={value.value}
                                                                                                    onChange={(value) => handleColorChange(valIndex, value)}
                                                                                                />
                                                                                            </div>
                                                                                        ) : null}
                                                                                        <Input
                                                                                            placeholder="Value"
                                                                                            value={value.value}
                                                                                            onChange={(e) => handleValueChange(valIndex, 'value', e)}
                                                                                            className='w-100'
                                                                                            style={{ borderRadius: '0px 5px 5px 0px' }}
                                                                                        />
                                                                                    </div>
                                                                                    <Divider type='vertical' />
                                                                                    <Input
                                                                                        placeholder="Label"
                                                                                        value={value.label}
                                                                                        onChange={(e) => handleValueChange(valIndex, 'label', e)}
                                                                                        className='w-100'
                                                                                    />
                                                                                    <Divider type='vertical' />
                                                                                    <DeleteOutlined className='item-action-btn-remove' onClick={() => handleDeleteValue(valIndex)} />
                                                                                </div>
                                                                            </Col>
                                                                        )}
                                                                    </Draggable>
                                                                ))}
                                                                <Button type="link" onClick={() => handleAddValue()}>Add value</Button>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                </div>
                            </Form.Item>
                        </Col>
                    )}

                    <Col xs={24} md={24} className='mb-3'>
                        <Button type="primary" onClick={handleAddFields}>
                            Create Fields
                        </Button>
                    </Col>
                </Row>
                <Table
                    size='small'
                    columns={columns}
                    dataSource={formFields}
                    rowKey="key"
                    pagination={false}
                    components={{
                        body: {
                            wrapper: dragEnabled ? DraggableContainer : undefined,
                            row: dragEnabled ? DraggableBodyRow : undefined,
                        },
                    }}
                />
                <EditOptions
                    visible={editModalVisible}
                    setVisible={setEditModalVisible}
                    data={editData}
                    setFormFields={setFormFields}
                />
            </Form>
        </>
    );
};

export default CustomOptions;
