import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "@ant-design/compatible";
import { Divider, Table, Input, Row, Col, InputNumber, Typography, Switch } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import BaseSelect from '../../components/Elements/BaseSelect';
import { getListAttributes } from "../../redux/actions/AttributesAction";
import { getListAttributeGroups } from "../../redux/actions/AttributeGroupAction";

const { Title } = Typography;

class VariantsList extends Component {
    constructor() {
        super();
        this.state = {
            selectedColumns: [],
            variants: [],
            products: [],
            selectedAttributes: {},
            selectedGroups: []
        };
    }

    componentDidMount() {
        this.props.getListAttributeGroups({ status: 1 });
        if (this.props.currentData) {
            const group_ids = this.props.currentData.attribute_group_ids.join(',') || 1;
            this.setState({ selectedGroups: this.props.currentData.attribute_group_ids });
            this.handleGetListAttributes(group_ids);
        }
    }

    // Hàm kết hợp các giá trị thuộc tính để tạo biến thể
    generateVariants = () => {
        const { selectedAttributes, variants } = this.state; // Lấy variants từ state
        const attributeKeys = Object.keys(selectedAttributes).filter(key => {
            const values = selectedAttributes[key];
            return values !== null && values !== undefined && values.length > 0;
        });

        if (attributeKeys.length === 0) {
            // Nếu không có thuộc tính nào được chọn, xóa hết các biến thể
            this.setState({ variants: [] }, () => {
                this.sendDataToParent({ variants: [] }) // Gửi data cập nhật sang component cha
            });
            return;
        }

        const combinations = (values, prefix = []) => {
            if (!values.length) return [prefix];
            const [first, ...rest] = values;
            return first.flatMap(value => combinations(rest, [...prefix, value]));
        };

        const newVariants = combinations(attributeKeys.map(key => selectedAttributes[key]))
            .map((combination, index) => {
                // Kiểm tra xem biến thể đã tồn tại chưa
                const existingVariant = variants.find(variant =>
                    variant.attributes.length === combination.length &&
                    variant.attributes.every((attr, i) =>
                        attr.attribute_id == attributeKeys[i] &&
                        attr.attribute_value_id == combination[i]
                    )
                );

                if (existingVariant) {
                    // Nếu biến thể đã tồn tại, giữ nguyên id và cập nhật các trường cần thiết
                    return {
                        ...existingVariant,
                        id: existingVariant.id + Math.random(), // Đảm bảo id là duy nhất
                        price: existingVariant.price,
                        in_stock: existingVariant.in_stock,
                        sku: existingVariant.sku,
                    };
                } else {
                    // Tạo biến thể mới nếu chưa tồn tại
                    return {
                        id: variants.length + index, // Đảm bảo id là duy nhất
                        attributes: attributeKeys.map((key, i) => ({
                            attribute_id: key,
                            attribute_value_id: combination[i]
                        })),
                        sku: null,
                        price: null,
                        in_stock: 1
                    };
                }
            });

        const mergedVariants = newVariants.map(newVariant => {
            const existingVariant = variants.find(variant =>
                variant.attributes.length === newVariant.attributes.length &&
                variant.attributes.every((attr, i) =>
                    attr.attribute_id == newVariant.attributes[i].attribute_id &&
                    attr.attribute_value_id == newVariant.attributes[i].attribute_value_id
                )
            );

            if (existingVariant) {
                // Nếu biến thể đã tồn tại, cập nhật các trường cần thiết từ biến thể cũ
                return {
                    ...newVariant,
                    price: existingVariant.price,
                    in_stock: existingVariant.in_stock,
                    sku: existingVariant.sku,
                };
            }
            return newVariant;
        });

        this.setState({ variants: mergedVariants }, () => {
            this.sendDataToParent({ variants: mergedVariants }) // Gửi data cập nhật sang component cha
        });
    };

    // Xử lý thay đổi thuộc tính
    handleAttributeChange = (attributeId, valueIds) => {
        this.setState(prevState => {

            // Tạo danh sách cột mới dựa trên thuộc tính được chọn
            let newSelectedColumns = [...prevState.selectedColumns];

            // Kiểm tra nếu valueIds có giá trị hoặc không
            if (valueIds.length > 0) {
                // Nếu có giá trị, thêm attributeId vào selectedColumns nếu chưa có
                if (!newSelectedColumns.includes(attributeId)) {
                    newSelectedColumns = [...newSelectedColumns, attributeId];
                }
            } else {
                // Nếu không có giá trị, xóa attributeId khỏi selectedColumns
                newSelectedColumns = newSelectedColumns.filter(id => id !== attributeId);
            }

            return {
                selectedAttributes: { ...prevState.selectedAttributes, [attributeId]: valueIds },
                selectedColumns: newSelectedColumns
            };
        }, this.generateVariants); // Gọi generateVariants sau khi cập nhật state
    };

    openDeleteVariant = (id) => {
        this.setState(prevState => ({
            variants: prevState.variants.filter(variant => variant.id !== id)
        }), () => {
            this.sendDataToParent({ variants: this.state.variants }) // Gửi data cập nhật sang component cha
        });
    };

    handleChangeGroup = (value) => {
        const group_id = value.length > 0 ? value.join(',') : 1;
        if (value.length === 0) {
            this.setState({
                selectedAttributes: {},
                selectedColumns: [],
                variants: [],
                attributes: []
            });
        } else {
            this.handleGetListAttributes(group_id);
        }
        this.setState({ selectedGroups: value }, () => {
            this.sendDataToParent({ variants: this.state.variants, group_ids: value }) // Gửi data cập nhật sang component cha
        });
    }

    handleGetListAttributes = (group_id) => {
        // Lấy danh sách thuộc tính từ store
        this.props.getListAttributes({ status: 1, group_id: group_id }).then(res => {

            if (this.props.currentData) {
                // Đặt dữ liệu thuộc tính mặc định vào state
                const initialAttributes = this.props.currentData.attribute_values_ids.reduce((acc, id) => {
                    //const attr = attributes.find(attr => attr.values.some(value => value.id === id));
                    const attr = this.props.attributes.find(attr => attr.values.some(value => value.id === id));
                    if (attr) {
                        acc[attr.id] = [...(acc[attr.id] || []), id];
                    }
                    return acc;
                }, {});
                this.setState({
                    selectedAttributes: initialAttributes,
                    selectedColumns: this.props.currentData?.attribute_ids // Lấy id thuộc tính từ dữ liệu hiện tại
                }, () => {
                    this.generateVariants(); // Gọi hàm generateVariants khi component mount
                });

                //console.log('state variants: ', this.state.variants);

                const { variants } = this.props.currentData || { variants: [] };
                // Lọc các biến thể mới chỉ khi chúng tồn tại trong biến thể cũ
                // Áp dụng cho trường hợp cập nhật sản phẩm
                const filteredVariants = variants.filter(variant =>
                    variants.some(oldVariant =>
                        oldVariant.attributes.length === variant.attributes.length &&
                        oldVariant.attributes.every((attr, i) =>
                            attr.attribute_id == variant.attributes[i].attribute_id &&
                            attr.attribute_value_id == variant.attributes[i].attribute_value_id
                        )
                    )
                );

                // Cập nhật các biến thể mới với dữ liệu từ biến thể cũ
                const updatedVariants = filteredVariants.map(variant => {
                    const existingVariant = variants.find(oldVariant =>
                        oldVariant.attributes.length === variant.attributes.length &&
                        oldVariant.attributes.every((attr, i) =>
                            attr.attribute_id == variant.attributes[i].attribute_id &&
                            attr.attribute_value_id == variant.attributes[i].attribute_value_id
                        )
                    );

                    return {
                        ...variant,
                        price: existingVariant.price,
                        in_stock: existingVariant.in_stock,
                        sku: existingVariant.sku,
                    };
                });

                // Lưu variants đã được lọc và cập nhật vào state và truyền cho component cha
                this.setState({ variants: updatedVariants }, () => {
                    this.sendDataToParent({ variants: updatedVariants }) // Gửi data cập nhật sang component cha
                });
            }
        });
    }

    sendDataToParent = (data) => {
        const group_ids = this.state.selectedGroups;
        data.group_ids = group_ids;
        this.props.sendDataToParent({ data });
    }

    render() {
        const { variants, selectedColumns } = this.state;
        const { attributes, attribute_groups, currentData } = this.props;

        // Hàm xác định chiều rộng của cột
        const determineWidth = (display_type) => {
            switch (display_type) {
                case 'circle':
                    return 200;
                default:
                    return 140;
            }
        };

        const columns = attributes
            .filter(attr => selectedColumns.includes(attr.id))
            .map(attr => ({
                title: attr.name,
                dataIndex: attr.id,
                width: determineWidth(attr.display_type),
                render: (text, record) => {
                    //console.log('record', record);
                    // Tìm giá trị được chọn cho thuộc tính này trong dữ liệu của record
                    const selectedAttribute = record.attributes.find(a => a.attribute_id == attr.id);

                    // Nếu có giá trị, tìm label tương ứng
                    if (selectedAttribute) {
                        const selectedValue = attr.values.find(value => value.id == selectedAttribute.attribute_value_id);
                        if (selectedValue) {
                            if (attr.display_type == 'circle') {
                                return (
                                    <>
                                        <span style={{ background: selectedValue?.value }} className='item-color-variants'>&nbsp;</span>
                                        {selectedValue?.label} ({selectedValue?.value})
                                    </>
                                );
                            }
                            return (
                                <span>
                                    {selectedValue?.label}
                                </span>
                            );
                        }

                    }
                    return '';
                }
            })).concat([
                {
                    title: 'Price',
                    dataIndex: 'price',
                    key: 'price',
                    render: (text, record) => (
                        <InputNumber
                            placeholder="Enter Price"
                            min={0}
                            max={999999}
                            className="w-5"
                            defaultValue={record.price}
                            onChange={(value) => {
                                record.price = value;
                                this.sendDataToParent({ variants: this.state.variants }) // Gửi data cập nhật sang component cha
                            }}
                        />
                    )
                },
                {
                    title: 'In stock',
                    dataIndex: 'in_stock',
                    key: 'in_stock',
                    width: 80,
                    render: (text, record) => (
                        <Switch
                            size="small"
                            defaultChecked={record.in_stock === 1}
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            onChange={(value) => {
                                record.in_stock = value;
                                this.sendDataToParent({ variants: this.state.variants }) // Gửi data cập nhật sang component cha
                            }}
                        />
                    )
                },
                {
                    title: 'SKU',
                    dataIndex: 'sku',
                    key: 'sku',
                    render: (text, record) => (
                        <Input
                            placeholder="Enter SKU"
                            className="w-100"
                            defaultValue={record.sku}
                            onChange={(e) => {
                                record.sku = e.target.value;
                                this.sendDataToParent({ variants: this.state.variants }) // Gửi data cập nhật sang component cha
                            }}
                        />
                    )
                },
                {
                    title: '#',
                    key: 'actions',
                    width: 40,
                    render: (_, record) => (
                        <span>
                            <CloseOutlined onClick={() => this.openDeleteVariant(record.id)} className='item-action-btn-remove' />
                        </span>
                    ),
                }
            ]);


        return (
            <>
                <Row gutter={16} style={{ marginBottom: 20 }}>
                    <Col xs={24} md={24}>
                        <BaseSelect
                            options={attribute_groups || []}
                            defaultValue={currentData?.attribute_group_ids || []}
                            optionValue="id"
                            optionLabel="name"
                            placeholder="Choose one or multiple groups of attributes"
                            className="w-100 mt-3 mb-3"
                            mode="multiple"
                            onChange={(value) => this.handleChangeGroup(value)}
                        />
                    </Col>

                    {attributes.length > 0 && (
                        <Col xs={24} md={24}>
                            <Title level={5} className="mt-3 mb-3">Select attributes for creating variants</Title>
                        </Col>
                    )}

                    {attributes.map(attr => {
                        const options = attr.values || [];

                        // Lọc các giá trị defaultValue dựa trên attribute_values_ids
                        const defaultValue = (this.props.currentData?.attribute_values_ids || [])
                            .filter(id => options.some(option => option.id === id));

                        return (
                            <Col xs={24} md={12} key={attr.id}>
                                <Form.Item label={attr.name}>
                                    <BaseSelect
                                        options={options}
                                        defaultValue={defaultValue}
                                        optionValue="id"
                                        optionLabel="label"
                                        additionalLabel={attr.display_type == 'circle' ? 'value' : undefined}
                                        placeholder={`Select ${attr.name}`}
                                        onChange={valueIds => this.handleAttributeChange(attr.id, valueIds)}
                                        className="w-100"
                                        mode="multiple"
                                    />
                                </Form.Item>
                            </Col>
                        );
                    })}
                </Row>

                <Divider orientation="left" orientationMargin={0}>Variants</Divider>
                <Table
                    rowKey="id"
                    size='small'
                    tableLayout='auto'
                    columns={columns}
                    dataSource={variants}
                    pagination={false}
                    style={{ marginTop: '20px' }}
                    locale={{ emptyText: "No data" }}
                    scroll={{
                        x: 'max-content'
                    }}
                />
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        attributes: state.AttributesReducer.attributes_list,
        attribute_groups: state.AttributeGroupReducer.attribute_group_list
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getListAttributeGroups: (filter) => dispatch(getListAttributeGroups(filter)),
        getListAttributes: (filter) => dispatch(getListAttributes(filter)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: "VariantsList" })(VariantsList));