import { combineReducers } from 'redux';
import AuthReducer from './AuthReducer';
import UserReducer from "./UserReducer";
import PermissionReducer from "./PermissionReducer";
import RoleReducer from "./RoleReducer";
import ConfigReducer from "./ConfigReducer";
import ProductsReducer from './ProductsReducer';
import ProductCategories from './ProductCategoriesReducer';
import TagsReducer from './TagsReducer';
import PostsReducer from './PostsReducer';
import ManageMenusReducer from './ManageMenusReducer';
import PostCategoriesReducer from './PostCategoriesReducer';
import AttributesReducer from './AttributesReducer';
import AttributeGroupReducer from './AttributeGroupReducer';
import BannerReducer from './BannerReducer';
import PaymentGatewayReducer from './PaymentGatewayReducer';

const rootReducer = combineReducers({
    auth: AuthReducer,
    user: UserReducer,
    permission: PermissionReducer,
    role: RoleReducer,
    config: ConfigReducer,
    ProductsReducer: ProductsReducer,
    ProductCategories: ProductCategories,
    TagsReducer: TagsReducer,
    PostsReducer: PostsReducer,
    ManageMenusReducer: ManageMenusReducer,
    PostCategoriesReducer: PostCategoriesReducer,
    AttributesReducer: AttributesReducer,
    BannerReducer: BannerReducer,
    AttributeGroupReducer: AttributeGroupReducer,
    PaymentGatewayReducer: PaymentGatewayReducer
});

export default rootReducer;