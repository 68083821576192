// auth
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const GET_AUTH_USER = 'GET_AUTH_USER';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const UPDATE_AUTH_USER = 'UPDATE_AUTH_USER';

// User Actions
export const GET_ALL_USERS = "GET_ALL_USERS";
export const GET_SPECIFIC_USER = "GET_SPECIFIC_USER";
export const CREATE_NEW_USER = "CREATE_NEW_USER";
export const UPDATE_SPECIFIC_USER = "UPDATE_SPECIFIC_USER";
export const REMOVE_USERS = "REMOVE_USERS";

// permission
export const GET_ALL_PERMISSIONS = "GET_ALL_PERMISSIONS";
export const UPDATE_ROLE_PERMISSIONS = "UPDATE_ROLE_PERMISSIONS";
export const GET_PERMISSIONS_BY_ROLE = "GET_PERMISSIONS_BY_ROLE";
export const GET_PERMISSIONS_BY_USER = "GET_PERMISSIONS_BY_USER";

// role
export const GET_ALL_ROLES = "GET_ALL_ROLES";
export const CREATE_NEW_ROLE = "CREATE_NEW_ROLE";
export const UPDATE_SPECIFIC_ROLE = "UPDATE_SPECIFIC_ROLE";
export const REMOVE_ROLES = "REMOVE_ROLES";
export const GET_SPECIFIC_ROLE = "GET_SPECIFIC_ROLE";

// config
export const GET_CONFIG = "GET_CONFIG";
export const SET_CONFIG = "SET_CONFIG";

// Products
export const LIST_PRODUCTS = "LIST_PRODUCTS";
export const GET_ONE_PRODUCTS = "GET_ONE_PRODUCTS";
export const DELETE_PRODUCTS = "DELETE_PRODUCTS";
export const CREATED_PRODUCTS = "CREATED_PRODUCTS";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";

// Products Categories
export const GET_LIST_PRODUCT_CATEGORIES = "GET_LIST_PRODUCT_CATEGORIES";
export const GET_SPECIFIC_PRODUCT_CATEGORIES = "GET_SPECIFIC_PRODUCT_CATEGORIES";
export const UPDATE_SPECIFIC_PRODUCT_CATEGORIES = "UPDATE_SPECIFIC_PRODUCT_CATEGORIES";
export const CREATE_NEW_PRODUCT_CATEGORIES = "CREATE_NEW_PRODUCT_CATEGORIES";
export const REMOVE_PRODUCT_CATEGORIES = "REMOVE_PRODUCT_CATEGORIES";
export const GET_PRODUCT_CATEGORIES_ACTIVE = "GET_PRODUCT_CATEGORIES_ACTIVE";

// ATTRIBUTES
export const GET_LIST_ATTRIBUTES = "GET_LIST_ATTRIBUTES";
export const GET_SPECIFIC_ATTRIBUTES = "GET_SPECIFIC_ATTRIBUTES";
export const UPDATE_SPECIFIC_ATTRIBUTES = "UPDATE_SPECIFIC_ATTRIBUTES";
export const CREATE_NEW_ATTRIBUTES = "CREATE_NEW_ATTRIBUTES";
export const REMOVE_ATTRIBUTES = "REMOVE_ATTRIBUTES";
export const UPDATE_SORT_ORDER_ATTRIBUTES = "UPDATE_SORT_ORDER_ATTRIBUTES";

// Attribute Gropus
export const GET_LIST_ATTRIBUTE_GROUPS = "GET_LIST_ATTRIBUTE_GROUPS";
export const GET_SPECIFIC_ATTRIBUTE_GROUPS = "GET_SPECIFIC_ATTRIBUTE_GROUPS";
export const UPDATE_SPECIFIC_ATTRIBUTE_GROUPS = "UPDATE_SPECIFIC_ATTRIBUTE_GROUPS";
export const CREATE_NEW_ATTRIBUTE_GROUPS = "CREATE_NEW_ATTRIBUTE_GROUPS";
export const REMOVE_ATTRIBUTE_GROUPS = "REMOVE_ATTRIBUTE_GROUPS";

// Post Categories
export const GET_LIST_POST_CATEGORIES = "GET_LIST_POST_CATEGORIES";
export const GET_SPECIFIC_POST_CATEGORIES = "GET_SPECIFIC_POST_CATEGORIES";
export const UPDATE_SPECIFIC_POST_CATEGORIES = "UPDATE_SPECIFIC_POST_CATEGORIES";
export const CREATE_NEW_POST_CATEGORIES = "CREATE_NEW_POST_CATEGORIES";
export const REMOVE_POST_CATEGORIES = "REMOVE_POST_CATEGORIES";
export const GET_POST_CATEGORIES_ACTIVE = "GET_POST_CATEGORIES_ACTIVE";

// Tags
export const LIST_TAGS = "LIST_TAGS";
export const CREATE_TAGS = "CREATE_TAGS";
export const DELETE_TAGS = "DELETE_TAGS";

// posts
export const GET_LIST_POSTS = "GET_LIST_POSTS";
export const GET_ONE_POSTS = "GET_ONE_POSTS";
export const CREATE_POSTS = "CREATE_POSTS";
export const UPDATE_POSTS = "UPDATE_POSTS";
export const REMOVE_POSTS = "REMOVE_POSTS";

// Config menu
export const GET_MENUS = "GET_MENUS";
export const GET_SPECIFIC_MENUS = "GET_SPECIFIC_MENUS";
export const UPDATE_SPECIFIC_MENUS = "UPDATE_SPECIFIC_MENUS";
export const CREATE_NEW_MENUS = "CREATE_NEW_MENUS";
export const REMOVE_MENUS = "REMOVE_MENUS";
export const ADD_CATEGORIES_TO_MENUS = "ADD_CATEGORIES_TO_MENUS";
export const REMOVE_MENUS_ITEMS = "REMOVE_MENUS_ITEMS";
export const GET_SPECIFIC_MENU_ITEMS = "GET_SPECIFIC_MENU_ITEMS";
export const UPDATE_SPECIFIC_MENU_ITEMS = "UPDATE_SPECIFIC_MENU_ITEMS";
export const UPDATE_SPECIFIC_All_MENU_ITEMS = "UPDATE_SPECIFIC_All_MENU_ITEMS";
export const ADD_CUSTOM_ITEM_TO_MENUS = "ADD_CUSTOM_ITEM_TO_MENUS";


// Banner
export const GET_LIST_BANNER = "GET_LIST_BANNER";
export const GET_SPECIFIC_BANNER = "GET_SPECIFIC_BANNER";
export const UPDATE_SPECIFIC_BANNER = "UPDATE_SPECIFIC_BANNER";
export const CREATE_NEW_BANNER = "CREATE_NEW_BANNER";
export const REMOVE_BANNER = "REMOVE_BANNER";

// Options
export const GET_OPTION = "GET_OPTION";
export const UPDATE_OPTION = "UPDATE_OPTION";
export const DELETE_OPTION = "DELETE_OPTION";

// Payment Gateway
export const GET_PAYMENT_GATEWAY = "GET_PAYMENT_GATEWAY";
export const UPDATE_PAYMENT_GATEWAY = "UPDATE_PAYMENT_GATEWAY";
export const DELETE_PAYMENT_GATEWAY = "DELETE_PAYMENT_GATEWAY";
export const CREATE_PAYMENT_GATEWAY = "CREATE_PAYMENT_GATEWAY";