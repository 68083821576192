import {
    GET_PAYMENT_GATEWAY
} from '../actions/types';

const INIT_STATE = {
    shield_list: [],
    config: {}
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_PAYMENT_GATEWAY:
            return {
                ...state,
                shield_list: action.payload.shields,
                config: action.payload.config
            };

        default:
            return { ...state }
    }
};
